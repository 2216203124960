import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import styled from 'styled-components'
import Button from '../../../../UI/Button'

const Header = styled.div`
  position: relative;
  background-image: linear-gradient(
  90deg
  ,#A5326C,#772550);
  height: 310px;
  > :first-child {
    height: 100%;
    max-width: 1100px;
    margin: 0 auto;
    display: flex;
  }
  color: #fff;

`

const TitleSection = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 0;
  width: 40%;
  h1 {
    margin: 0;
  }
  > :last-child {
    margin-top: auto;
    display: block;
    > :first-child { width: 100%; }
  }
  z-index: 2;
  @media (max-width: 1120px) {
    width: 100%;
    align-items: center;
    max-width: 500px;
    margin: 0 auto;
    text-align: center;
    padding: 20px;
    > :last-child {
      margin-top: auto;
    }
  }
`

const BigTitle = styled.h1`
  letter-spacing: 2px;
  text-shadow: none;
  font-size: 3rem;
  @media (max-width: 400px) {
    font-size: 2.7rem;
  }
`

const MaskedImage = styled.div`
  position: relative;
  width: 60%;
  align-self: stretch;
  height: 100%;
  overflow: hidden;
  > :first-child {
    position: absolute;
    width: 100%;
    height: 102%;
    top: 0;
    left: 0;
    object-fit: cover;
    clip-path: polygon(25% 0, 100% 0, 100% 100%, 0 100%, 0 100%)
  }
  @media (max-width: 1120px) {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;
    > :first-child {
      ::after {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: rgba(0,0,0,0.3);
      }
    }
  }
`

const BlogHero = () => <Header>
    <div>
      <TitleSection>
        <BigTitle>Inspire a love of reading and writing</BigTitle>
        <Link to='/parents'><Button theme='confirm' size='regular'>Discover</Button></Link>
      </TitleSection>
      <MaskedImage>
        <StaticImage src='../../../../../images/blog-header-image.jpeg' alt='Children looking at screen' placeholder='blurred' />
      </MaskedImage>
    </div>
  </Header>

export default BlogHero
