import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import BlogPageTemplate from "./template"

const BlogPage = () => {
  const { allStrapiBlogPosts, allStrapiBlogCategories } =
    useStaticQuery(graphql`
      {
        allStrapiBlogCategories {
          edges {
            node {
              slug
              title
            }
          }
        }
        allStrapiBlogPosts(
          sort: { fields: [originalPublishDate], order: DESC }
        ) {
          edges {
            node {
              title
              slug
              excerpt
              originalPublishDate
              thumbnail {
                url
                localFile {
                  childImageSharp {
                    gatsbyImageData(placeholder: BLURRED, width: 400)
                  }
                }
              }
              author {
                slug
                fullName
                avatar {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(placeholder: BLURRED, width: 100)
                    }
                  }
                }
              }
            }
          }
        }
      }
    `)

  return (
    <BlogPageTemplate
      categories={(allStrapiBlogCategories.edges || []).map(c => c.node)}
      posts={(allStrapiBlogPosts.edges || []).map(e => e.node)}
    />
  )
}

export default BlogPage
