import { graphql, Link, useStaticQuery } from "gatsby"
import React from "react"
import styled, { css } from "styled-components"
import SEO from 'components/SEO'
import { FONT_FAMILIES } from "../../../styles/Theme"
import BlogHero from "./components/BlogHero"
import { IPostCard } from "./components/PostCard"
import PostsGrid from "./components/PostsGrid"

const Wrapper = styled.div`
  background-image: linear-gradient(90deg, #9e2b67, #772550);
  color: rgb(70, 70, 70);
  padding-top: 50px;
  padding-bottom: 50px;
  color: #fff;
`

const Content = styled.div`
  max-width: 1100px;
  margin: 0 auto;
`

const Header = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 15px;
  margin-top: 15px;
`

const Category = styled.div`
  position: relative;
  padding: 5px 20px;
  font-weight: 700;
  ::after {
    position: absolute;
    content: "";
    bottom: 0;
    left: 20px;
    width: calc(100% - 40px);
    ${({ selected }: { selected: boolean }) =>
      selected &&
      css`
        border-bottom: 1px solid #fff;
      `}
  }
`

const BlogBanner = styled.div`
  font-size: 2rem;
  line-height: 2.3;
  color: #fff;
  ${FONT_FAMILIES.rammetto};
  background-image: linear-gradient(90deg, #d63689, #903062);
  > :first-child {
    max-width: 1100px;
    margin: 0 auto;
    font-size: 2rem;
    line-height: 2.3;

  }

  @media (max-width: 1120px) {
    padding: 0 20px;
    > :first-child {
      font-size: 22px !important;
    }
  }
`

interface IProps {
  categorySlug?: string
  posts: IPostCard[]
  categories: { slug: string; title: string }[]
}

const BlogPageTemplate = (props: IProps) => {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          image
          siteUrl
        }
      }
    }`
  )

  const category = props.categories.find(c => c.slug === props.categorySlug)

  const SEOProps = {
    title: category?.title || `Blog`,
    titleTemplate: "%s | Night Zookeeper Blog",
    image: site.siteMetadata.image,
    url: category ? `${site.siteMetadata.siteUrl}/blog/categories/${category.slug}` : `${site.siteMetadata.siteUrl}/blog`,
    canonical: `${site.siteMetadata.siteUrl}/blog`,
    description: site.siteMetadata.description,
  }

  return <>
    <Wrapper>
      <BlogHero />
      <BlogBanner>
        <h1>Night Zookeeper Blog</h1>
      </BlogBanner>
      <Content>
        <Header>
          {props.categories.map(c => (
            <Link key={c.slug} to={`/blog/categories/${c.slug}`}>
              <Category selected={c.slug === category?.slug}>
                {c.title}
              </Category>
            </Link>
          ))}
        </Header>
        <PostsGrid posts={props.posts} />
      </Content>
    </Wrapper>
    <SEO {...SEOProps} />
  </>
}

BlogPageTemplate.defaultProps = {
  categorySlug: null,
}

export default BlogPageTemplate
