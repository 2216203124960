import { graphql } from 'gatsby'
import React from 'react'
import BlogPage from '../../components/pages/Blog'
import SEO from '../../components/SEO'
import Layout from '../../layouts/Main'

const Blog = ({ data }) => <Layout>
    <SEO title='Blog' url={`${data.site.siteMetadata.siteUrl}/blog`} canonical={`${data.site.siteMetadata.siteUrl}/blog`} />
    <BlogPage />
  </Layout>

export const query = graphql`
  {
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`

export default Blog
